.homepage,
.tickets,
.competitions,
.offers,
.withdrawals {
    display: flex;
    height: 100%;
    min-height: 100vh;
}

.homepage-content,
.tickets-content,
.competitions-content,
.offers-content,
.withdrawals-content,
.upload-content {
    flex: 1;
    text-align: center;
    overflow: auto;
}

#logo-menu-div {
    text-align: left;
    margin-bottom: 50px;
    position: relative;
}

.exit-menu-div {
    position: absolute;
    z-index: 9;
    top: 18px;
    right: -18px;
    background-color: #fff;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.exit-menu-div.hidden {
    opacity: 0;
    transition: opacity .5s ease-in;
}

.menu-search {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    box-sizing: border-box;
    position: relative;
    opacity: .5;
    transition: .3s opacity linear;
    color: #fff;
    border-bottom: 1px solid #FFFFFF;
}

.menu-search:has(input:focus) {
    opacity: 1;
}

.admin-card {
    margin: 30px 0;
    gap: 10px;
}

.content-container {
    padding: 10px;
    height: calc(100vh - 115px);
    position: relative;
}

.user-table-container {
    background-color: #fff;
    margin-top: 12px;
    padding: 5px;
}

.user-table-handlers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    padding: 5px;
}

.confirm-action-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.50);
}

.user-action-modal {
    height: 500px;
}
.confirm-action-modal.open {
    display: block;
}

.confirm-action-modal-body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 444px;
    height: 335px;
    border-radius: 8px;
    background: var(--Color-4, #FFF);
    padding: 34px 20px 22px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
}
.confirm-action-modal-flow {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20%;
}
.confirm-action-modal-body.note, .confirm-action-modal-body.bonus {
    width: 444px;
    height: fit-content;
}

.confirm-action-modal-body.suspend_user,
.confirm-action-modal-body.delete_user {
    height: 500px;
}
.confirm-action-modal-buttons {
    display: flex;
    justify-content: center;
    gap: 14px;
}
.user-table-handlers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    padding: 5px;
}

.user-table-handlers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    padding-left: 5px;
}

.user-page {
    max-width: 80vw;
    position: relative;
    /* z-index: 8; */
}

.user-page-container {
    padding: 16px 25px;
    width: 100%;
}

.user-page-content {
    border-radius: 8px;
    background: #FFF;
    padding: 18px 22px;
}

.user-page-container {
    padding: 16px 25px;
    width: 100%;
}

.user-page-place-container {
    position: relative;
}

.user-page-place-image {
    width: 38px;
    height: 38px;
}

.user-page-place.number,
.user-page-place.value {
    position: absolute;
    right: 50%;
    transform: translate(50%, -50%);
}

.user-page-place.number {
    top: 30%;
}

.user-page-place.value {
    font-size: 10px;
    top: 60%;
}
.users-stats-container {
    display: flex;
    gap: 30px;
    margin-bottom: 7px;
}

.users-stats {
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #ffffff;
    width: 185px;
    border-radius: 8px;
    padding: 12px 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 170%;
}

.users-stats:hover {
    outline: 1px solid #141629;
}

.tests-page {
    width: 100%;
}

.email-tests-tab {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
}

.email-tests-rules-container {
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.email-tests-rules-container:hover {
    transform: scale(1.015);
}

.email-tests-rules {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
}
div.admin-swal-popup{
    border-radius: 8px;
    padding: 20px 0;
}

div.admin-swal-modal{
    border-radius: 8px;
}

.admin-swal-modal .swal2-title {
    text-align: left;
    padding-top: 10px;
    font-size: 25px;
}

.give-bonus-swal-hr {
    height: 1px;
    background: #1B1C1E;
    opacity: .1;
    border: none;
}

div.swal2-popup:has(.successful-alert) {
    border-radius: 8px !important;
}

.swal2-container:has(.successful-alert) {
    display: flex;
    justify-content: center;
}

.success-alert-popup {
    margin-top: 30px;
    align-self: flex-start !important;
}

.swal2-popup .swal2-styled,
.swal2-popup .swal2-close {
    box-shadow: none !important;
}

.header-welcome-container {
    padding-left: 20px;
    flex: 40%;
}

.header-search-container {
    flex: 60%;
}

.intro-survey-quiz-range-slider {
    padding-bottom: 20px;
}

.intro-survey-quiz-font.heading {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.intro-survey-quiz-font.heading.purple {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.slider-container {
    user-select: none;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background: linear-gradient(85.33deg, #5733A3 -20.5%, #9F7DDB 47.62%, #DB7D7D 111.62%);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 0;
    cursor: pointer;
    background: #7956BD;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.29);
    border-radius: 50%;
}

.slider-numbers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #282B54;
}

.slider-numbers span {
    padding-left: 10px;
    opacity: 0.5;
}

.slider-numbers span.active {
    opacity: 1;
}

.slider-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.slider-buttons img {
    cursor: pointer;
}

.slider-buttons img[disabled] {
    opacity: .2;
}

.dropdown-option {
    width: 200px;
    background: #9F7DDB;
    border-radius: 5px;
    color: #fff;
    z-index: 10;
    opacity: 100%;
    margin-bottom: 2px;
    padding: 2px;
}

.expanded-dropdown {
    width: 220px;
}


.dropdown-option:hover {
    background: #5352B5;
}
.user-profile-list {
    display: flex;
    gap: 15px;
    margin-bottom: 24px;
    flex-wrap: wrap;
}
.user-profile-list.coins {
    gap: 18px;
}

.profile-card {
    cursor: pointer;
    padding: 12px 0px 12px 8px;
        width: 140px;
    height: 155px;
    border-radius: 8px;
    box-shadow: 0px 6px 54px rgba(159, 125, 219, 0.25);
    transition: all 0.2s ease-in-out;
}

.profile-card.referrals {
    width: 170px;
    height: 137px;
}

.profile-card:hover {
    transform: scale(1.05);
}

.profile-card-bg-image {
    width: 100%;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 20px;
}

.profile-payment-method-card {
    width: 225px;
    height: 155px;
    padding: 12px 20px;
}

.profile-card-bg-image-payment {
    margin-bottom: 10px;
    height: 75px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.profile-card.note {
    width: 273px;
    height: 184px;
    padding: 18px 14px 15px 20px;
}

.note-heading-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-button {
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    padding-top: 15px;
}

.tooltip-text {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    white-space: nowrap;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-button:hover+.tooltip-text {
    visibility: visible;
    opacity: 1;
}

.action-popup-container div{
    width: 100%;
}
.action-popup-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 18px;
    align-items: start;
    width: 100%;
}

.admin-comments-cell{
    position: relative;
}

.admin-comments-full {
    display: none !important;
}

.admin-comments-cell:hover .admin-comments-full {
    display: block !important;
    position: absolute !important;
    z-index: 5;
    top: 0;
    background-color: #fff;
    padding: 4px;
    border-radius: 5px;
    width: 200px;
}

.banned-postbacks-page{
    width: 100%;
}