html,
body {
  font-family: Poppins;
  margin: 0;
  background-color: #f4f4f4;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  cursor: pointer;
}

#root,
#root-div {
  height: 100%;
}

#main {
  flex: 1 0 auto;
  background-color: #141629;
}

#content {
  flex: 1 0 auto;
}

#main.light-mode {
  background: transparent;
}

.background-transparent {
  background-color: transparent;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.width-185{
  width: 185px;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.hidden {
  display: none !important;
}

.block {
  display: block;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-50 {
  flex: 50%;
}

.align-self-start {
  align-self: flex-start;
}
.items-start {
  align-items: flex-start;
}

.items-stretch {
  align-items: stretch;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.gap-80 {
  gap: 80px;
}

.hight-80 {
  height: 80%;
  max-height: 80%;
}
.black {
  color: #000 !important;
}

.white {
  color: #fff !important;
}

.red {
  color: #ff1d1d !important;
}

.oceanic {
  color: #1976d2 !important;
}

.font {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
}

.italic {
  font-style: italic !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.font.bold {
  font-weight: 700 !important;
}
.font.bold.aqua {
  color: #37ffbc;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.opacity {
  opacity: 0.5;
}

p.text-center,
h1.text-center,
h2.text-center,
div.text-center {
  text-align: center !important;
}

p.text-left,
h1.text-left,
h2.text-left,
div.text-left {
  text-align: left !important;
}

p.text-right,
h1.text-right,
h2.text-right,
div.text-right {
  text-align: right !important;
}

.overflow-hidden {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-start {
  justify-self: start;
  padding: 10px 30px;
}

.header-desc {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #1b1c1e;
  opacity: 0.5;
  text-align: start;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.margin-right-60 {
  margin-right: 60px;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-x-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.margin-0 {
  margin: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-left-40 {
  padding-left: 40px !important;
}

.text-underline {
  text-decoration: underline;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.overflow-visible {
  overflow: visible !important;
}

.display-none {
  display: none;
  max-height: 0px;
  opacity: 0;
}


/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #727272;
}

.table-container{
  max-height: calc(100vh - 255px);
}

.table-container.normal{
  height: calc(100vh - 300px);
  max-height: inherit;
}
.table-container.full-height{
  height: calc(100vh - 255px);
  max-height: inherit;
}

.table-footer{
  width: 100%;
  padding: 15px;
  border-top: 1px solid rgba(224, 224, 224, 1);
}
.loader{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loader-mask{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.8);
  z-index: 999;
}

.max1Lins{
  overflow: hidden;
  display: -webkit-box;
  display: box;
  -webkit-line-clamp: 1;
  line-clamp:1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  max-width: 200px;
}

.max2Lins{
  overflow: hidden;
  display: -webkit-box;
  display: box;
  -webkit-line-clamp: 2;
  line-clamp:2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  max-width: 200px;
}

.max3Lins{
  overflow: hidden;
  display: -webkit-box;
  display: box;
  -webkit-line-clamp: 3;
  line-clamp:3;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  max-width: 200px;
}

.offers-copy-icon{
  cursor: pointer;
}

.copyToCliboardSuccess, .copied .copyToCliboardPrimary{
  display: none;
}

.copied .copyToCliboardSuccess{
  display: block;
}

.swal2-popup ::-webkit-scrollbar {
  height: 5px;
}
