.heading-h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #141629;
    text-align: left;
    margin-bottom: 5px;
}

.heading-h2.bold {
    font-weight: 700;
    font-size: 30px;
    margin: 10px 0;
}

.error-message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 170%;
    color: #EC1717;
    -webkit-transition: opacity 0.25s ease-in;
    -moz-transition: opacity 0.25s ease-in;
    -o-transition: opacity 0.25s ease-in;
    transition: opacity 0.25s ease-in;
}

.hastags-error-display {
    font-size: 20px;
    max-height: 0;
    position: absolute;
    z-index: 4;
    top: 5px;
    overflow: hidden;
    transition: all 0.5s ease;
}

.hastags-error-display:focus {
    max-height: 35px;
}

p.successful-alert {
    margin-top: 0;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #282B54;
    max-width: 90%;
}

h2.close-modal-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    color: #141629;
}

p.close-modal-description {
    margin-top: 0;
    margin-bottom: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 170%;
    color: #1B1C1E;
}

.comment-ticket-popup-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 170%;
    color: #1B1C1E;
}

.user-table-search-name {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
}
.profile-card-desc {
    color: #1B1C1E;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding-left: 5px;
    margin-right: 2px;
    overflow: hidden;
}

.user-profile-category-heading {
    color: #1B1C1E;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}

.profile-card-desc.bold {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
}

.profile-card-desc.number {
    font-size: 20px;
}
.profile-card-desc.referrals {
    font-size: 24px;
}

.profile-card-desc.activity {
    font-size: 14px;
}

.profile-card-desc.email {
    color: #685EBF;
    text-align: center;
    font-size: 14px;
    overflow: hidden;
    font-weight: 500;
    margin-bottom: 10px;
}

.profile-card-desc.id {
    overflow: hidden;
    color: #685EBF;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 8px;
    font-weight: 500;
}

.note-heading-text {
    color: #1B1C1E;
    font-size: 14px;
    font-weight: 600;
    width: 120px;
    overflow: hidden;
    text-wrap: nowrap;
}

.note-heading-date {
    font-size: 12px;
    opacity: 0.5;
}

.note-text {
    color: #1B1C1E;
    font-size: 14px;
    font-weight: 400;
}

.note-text .underlined {
    text-decoration: underline;
}

.note-author {
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    opacity: 0.5;
}

.note-author span{
    font-size: 20px;
}

.confirm-action-modal-heading.note{
    font-size: 30px;
}

.confirm-action-modal-comments{
    margin-bottom: 20px;
}
.confirm-action-modal-comments span{
    font-style: italic;

}

.rules-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #333;
}

.rules-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.rules-list li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    font-size: 16px;
    color: #555;
}