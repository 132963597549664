
.menu-option-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(255, 255, 255);
    display: inline-block;
    background-color: transparent;
    border: none;
    padding-left: 0px;
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
}

button.admin-modal-close-button {
    color: #000000;
    outline: none;
    margin-right: 20px;
}

button.swal2-styled.give-bonus-modal-cancel-button {
    width: 100px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #1B1C1E;
    border: 1px solid #1B1C1E;
    border-radius: 8px;
    background-color: transparent;
    outline: none !important;
    padding: 5px 0;
}

button.swal2-styled.give-bonus-modal-confirm-button {
    width: 100px;
    background: #1B1C1E;
    border: 1px solid #1B1C1E;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    outline: none !important;
    padding: 5px 0;
}

button.swal2-styled.give-bonus-modal-confirm-button[disabled] {
  opacity: 0.3;
}

button.swal2-styled.manage-subscriptions-modal-confirm-button {
    background: #1B1C1E;
    border: 1px solid #1B1C1E;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    outline: none !important;
    padding: 5px 15px;
}

.swal2-close.successful-alert {
    color: #000000;
}

.btn-withdrawal.activate,
.btn-withdrawal.deactivate {
  width: 86px;
  height: 24px;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 170%;
  color: #FFFFFF;
  opacity: 1;
}

.btn-withdrawal.activate {
  background: #5DBA24;
}

.btn-withdrawal.deactivate {
  background: #D83939;
}

.btn-withdrawal {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .btn-withdrawal svg {
    width: 15px;
    height: 15px;
    fill: #ffffff;
  }
  
  .approve {
    background-color: #5dba24;
    margin-right: 4px;
    color: #ffffff;
  }
  
  .reject {
    background-color: #d31c1c;
    color: #ffffff;
  }

  .action-button.refresh {
    background-color: #8e83eb;
    color: #ffffff;
  }

  .action-button.disabled{
    opacity: 0.5;
  }

  .edit {
    background-color: #1B1C1E;
    color: #ffffff;
  }
  
  .cancel{
    background-color: #ffffff;
    border: 1px solid #1B1C1E;
  }
  
  .action-button {
    width: 110px;
    border-radius: 8px;
    padding: 5px 20px;
    font-family: 'Poppins';
    display: flex;
      align-items: center;
      gap: 5px;
  }

.button-hidden {
  background: none;
  border: none;
}

.confirm-action-modal-close-btn{
  position: absolute;
  right: 21px;
  top: 21px;
}

.confirm-action-modal-close-icon {
  width: 14px;
  height: 14px;
}
.fetch-offers-button {
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 170%;
  color: #1B1C1E;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
}

.next-page-btn,
.prev-page-btn {
  padding: 5px 25px;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  font-size: x-large;
}

.next-page-btn:disabled,
.prev-page-btn:disabled {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.offers-done-action-btn {
  padding: 3px 10px;
  background: #000000;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 170%;
  text-align: center;
  color: #FFFFFF;
}

.offers-done-validate-btn {
  background: #FFFFFF;
  border: 1px solid rgba(27, 28, 30, 0.15);
  border-radius: 8px;
  padding: 10px 25px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 170%;
  color: #1B1C1E;
}

.validate-modal-btn {
  background: #FFFFFF;
  border: 1px solid rgba(27, 28, 30, 0.15);
  border-radius: 8px;
  padding: 10px 25px;
}

.swal2-actions button {
  min-width: 100px;
}

.swal2-confirm.swal2-styled{
  background: #1976d2;
}
.user-profile-action-btn {
  width: 135px;
  height: 30px;
  padding: 2px 10px;
  text-wrap: nowrap;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.user-profile-action-btn:hover {
  transform: translateY(-5px);
}