.menu-drawer {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 7;
    text-align: center;
    -webkit-transition: transform 0.2s ease-out, width 0.2s ease-out;
    -moz-transition: transform 0.2s ease-out, width 0.2s ease-out;
    -o-transition: transform 0.2s ease-out, width 0.2s ease-out;
    transition: transform 0.6s ease-out, width 0.2s ease-out;
    max-width: 300px;
    transition: transform 0.6s ease-out, max-width 0.2s ease-out;
    background: #141629;
    color: #FFF;
    flex: 300px 0 0;
}

.menu-drawer.hidden {
    -webkit-transform: translateX(-100%) translateZ(0);
    transform: translateX(-100%);
    overflow: hidden;
    max-width: 0;
    transform: translateX(-100%);
}
.menu-drawer-container {
    padding: 25px;
    height: 100vh;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 260px;
}

.menu-bottom-container {
    display: inline-flex;
    justify-content: flex-end;
    flex-direction: column;
    flex: 1;
    width: 200px;
}

@media (max-width: 768px) {
    .menu-drawer {
        flex: 200px 0 0;
    }

}